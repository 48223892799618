import React, { useState } from "react";
import "./header.css";
import logo from "../../images/fps-logo.png";
import { useInView } from "react-intersection-observer";
import { FaSteam, FaBars } from "react-icons/fa";

const Header = () => {
  const ScrollToTrailer = () => {
    const element = document.getElementsByClassName("hero-video")[0];
    element.scrollIntoView({ behavior: "smooth" });
  };

  const ScrollToFeatures = () => {
    const element = document.getElementsByClassName("features")[0];
    element.scrollIntoView({ behavior: "smooth" });
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [ref, InView] = useInView({
    /* Optional options */
    threshold: 0
  });

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="header" ref={ref}>
      <div className="wide-header">
        {InView ? (
          <div className="wide-header-content">
            <p className="link-trailer" onClick={ScrollToTrailer}>
              Trailer
            </p>
            <p className="link-features" onClick={ScrollToFeatures}>
              Features
            </p>
            <img src={logo} alt="FPS Logo" />
            <div className="wishlist-btn-container">
              <a
                className="wishlist-with-icon"
                href="https://store.steampowered.com/app/1319460/Fashion_Police_Squad/?utm_source=homepage"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>BUY ON</span> <FaSteam size="1.5em" />
              </a>
            </div>

            <a
              className="link-presskit"
              href="http://presskit.fashionpolicesquad.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              PRESSKIT
            </a>
          </div>
        ) : (
          <div className="wide-header-content following">
            <p className="link-trailer" onClick={ScrollToTrailer}>
              Trailer
            </p>
            <p className="link-features" onClick={ScrollToFeatures}>
              Features
            </p>
            <img src={logo} alt="FPS Logo" />
            <div className="wishlist-btn-container">
              <a
                className="wishlist-with-icon"
                href="https://store.steampowered.com/app/1319460/Fashion_Police_Squad/?utm_source=homepage"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>BUY ON</span> <FaSteam size="1.5em" />
              </a>
            </div>
            <a
              className="link-presskit"
              href="http://presskit.fashionpolicesquad.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              PRESSKIT
            </a>
          </div>
        )}
      </div>

      <div
        className={InView ? "dropdown-header" : "dropdown-header dropdown-bg"}
      >
        {!InView && (
          <img className="logo-small" src={logo} alt="FPS Logo"></img>
        )}
        <div className="dropdown-icon" onClick={toggleDropdown}>
          <FaBars color="white" size="2em" />
        </div>
        <div
          className="dropdown-content"
          style={{ display: dropdownOpen ? "flex" : "none" }}
        >
          <p className="link-trailer" onClick={ScrollToTrailer}>
            Trailer
          </p>
          <p className="link-features" onClick={ScrollToFeatures}>
            Features
          </p>
          <a
            className="wishlist-transparent"
            href="https://store.steampowered.com/app/1319460/Fashion_Police_Squad/?utm_source=homepage"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>BUY ON</span> <FaSteam size="1.5em" />
          </a>
          <a
            className="link-presskit"
            href="http://presskit.fashionpolicesquad.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            PRESSKIT
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
