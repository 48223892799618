import React from "react";

export default ({ youtubeId }) => {
  return (
    <div
      className="video"
      style={{
        maxWidth: "900px",
        margin: "0px auto"
      }}
    >
      <div
        style={{
          position: "relative",
          paddingBottom: "56.25%",
          height: "0px"
        }}
      >
        <iframe
          title="Youtube Player"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
          src={`https://www.youtube.com/embed/${youtubeId}`}
          frameBorder="0"
        />
      </div>
    </div>
  );
};
