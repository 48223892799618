import React from "react";
import "./hero.css";
import YouTubePlayer from "../../YoutubePlayer";

import logo from "../../images/logo-less-shadow.png";

const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="hero-content">
        <div className="hero-content-left">
          <div className="hero-img">
            <img src={logo} alt="Fashion Police Squad Logo"></img>
          </div>

          <p>
            THE MOST <span style={{ color: "#ffe8a6" }}>FABULOUS</span> OLD
            SCHOOL FPS!
          </p>
          <a
            className="wishlist"
            href="https://store.steampowered.com/app/1319460/Fashion_Police_Squad/?utm_source=homepage"
            target="_blank"
            rel="noopener noreferrer"
          >
            BUY IT ON STEAM
          </a>
          <br/>
          <a
            className="wishlist"
            href="https://linktr.ee/fpsconsole"
            target="_blank"
            rel="noopener noreferrer"
          >
            BUY IT ON CONSOLES
          </a>
        </div>
      </div>
      <div className="hero-video">
        <YouTubePlayer youtubeId="Fmphc43NRXU" />
        <p className="description">
          <span style={{ color: "#ffe8a6" }}>Fashion Police Squad</span> is a
          humorous Retro FPS where you fight against fashion crime using attire
          enhancing weaponry.
        </p>
        <div className="hero-video-cta">
          <a
              className="wishlist"
              href="https://store.steampowered.com/app/1319460/Fashion_Police_Squad/?utm_source=homepage"
              target="_blank"
              rel="noopener noreferrer"
            >
              BUY IT ON STEAM
          </a>
          <a
              className="wishlist"
              href="https://linktr.ee/fpsconsole"
              target="_blank"
              rel="noopener noreferrer"
            >
              BUY IT ON CONSOLES
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
