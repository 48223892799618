import React from "react";
import "./footer.css";

const Footer = () => {
  const ScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="footer">
      <div className="footer-left">
        <p>&#169; Mopeful Games {new Date().getFullYear()}</p>
      </div>
      <div className="footer-right">
        <p onClick={ScrollToTop}>BACK TO TOP</p>
      </div>
    </div>
  );
};

export default Footer;
