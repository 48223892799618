import React from "react";
import "./company.css";

const Company = () => {
  return (
    <div className="company">
      <div className="company-content">
        <h1>Mopeful Games</h1>
        <div style={{ backgroundColor: "#f4dfa0" }} className="line"></div>
        <p className="company-desc">
          Fashion Police Squad is being developed by Mopeful Games! We are a
          tiny indie game studio consisting of 3 friends from Finland.{" "}
        </p>
        <div className="company-ctas">
          <a href="mailto:mopefulgames1@gmail.com" className="pink-btn">
            Contact us
          </a>
          <a
            href="http://presskit.fashionpolicesquad.com"
            target="_blank"
            rel="noopener noreferrer"
            className="pink-btn"
          >
            Presskit
          </a>
        </div>
      </div>
    </div>
  );
};

export default Company;
