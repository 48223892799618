import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import ss1 from "../../images/ss5.png";
import ss2 from "../../images/ss3.png";
import ss3 from "../../images/fps-10-4@2x.png";
import ss4 from "../../images/ss4.png";
import ss5 from "../../images/ss1.png";
import ss6 from "../../images/fps-36@2x.png";
import carImg from "../../images/car.png";

import "./screenshots.css";

const Screenshots = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const images = [ss1, ss2, ss3, ss4, ss5, ss6];

  return (
    <div className="screenshots">
      <div className="screenshots-grid">
        <div className="screenshots-left">
          <img
            onClick={() => {
              setImageIndex(0);
              setIsLightboxOpen(true);
            }}
            loading="lazy" src={ss1}
            alt="Screenshot of the gameplay"
          ></img>
          <img
            onClick={() => {
              setImageIndex(1);
              setIsLightboxOpen(true);
            }}
            loading="lazy" src={ss2}
            alt="Screenshot of the gameplay"
          ></img>
          <img
            onClick={() => {
              setImageIndex(2);
              setIsLightboxOpen(true);
            }}
            loading="lazy" src={ss3}
            alt="Screenshot of the gameplay"
          ></img>
        </div>
        <div className="screenshots-right">
          <img
            onClick={() => {
              setImageIndex(3);
              setIsLightboxOpen(true);
            }}
            loading="lazy" src={ss4}
            alt="Screenshot of the gameplay"
          ></img>
          <img
            onClick={() => {
              setImageIndex(4);
              setIsLightboxOpen(true);
            }}
            loading="lazy" src={ss5}
            alt="Screenshot of the gameplay"
          ></img>
          <img
            onClick={() => {
              setImageIndex(5);
              setIsLightboxOpen(true);
            }}
            loading="lazy" src={ss6}
            alt="Screenshot of the gameplay"
          ></img>
        </div>
      </div>

      <img className="car-img" loading="lazy" src={carImg} alt="FPS Car" />

      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[imageIndex]}
          nextSrc={images[(imageIndex + 1) % images.length]}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex((imageIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setImageIndex((imageIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default Screenshots;
