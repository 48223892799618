import React from "react";
import "./features.css";

const Features = () => {
  return (
    <div className="features">
      <h1>FEATURES</h1>
      <div className="features-container">
        <div className="feature">
          <p className="feature-title">
            A <span style={{ color: "#ffe8a6" }}>FABULOUS</span> STORY CAMPAIGN
          </p>
          <div className="line"></div>
          <p className="feature-description">
            Get absorbed into the Fashion Police Universe by solving fashion
            crimes, collecting swag & drinking mocktails right off the street!
          </p>
        </div>
        <div className="feature">
          <p className="feature-title">
            <span style={{ color: "#ffe8a6" }}>FIERCE</span> RETRO FPS ACTION!
          </p>
          <div className="line"></div>
          <p className="feature-description">
            Sew, saturate, whip and blast your way through a dozen levels full
            of fashion crimes by using satisfying attire altering weapons!
          </p>
        </div>
        <div className="feature">
          <p className="feature-title">
            A <span style={{ color: "#ffe8a6" }}>FRESH</span> WEAPON SWITCHING
            MECHANIC
          </p>
          <div className="line"></div>
          <p className="feature-description">
            Most fashion crimes require unique solutions. Switch weapons on the
            fly and plan your attack to take out the most unstylish perpetrators
            first!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Features;
