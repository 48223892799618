import React from "react";
import Features from "./components/features";
import Header from "./components/header";
import HeroSection from "./components/hero";
import Screenshots from "./components/screenshots";
import Company from "./components/company";
import Footer from "./components/footer";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <Features />
      <Screenshots />
      <Company />
      <Footer />
    </div>
  );
}

export default App;
